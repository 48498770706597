.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.shopping-cart {
  position: relative;
  /*编辑*/
  /*商品列表*/
  /*结算*/
  /* 列表为空*/
}
.shopping-cart header {
  width: 100%;
  height: 0.88rem;
  background: #fff;
  padding: 0 0.48rem 0 0.24rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  font-size: 0.28rem;
  color: #333;
}
.shopping-cart header .edit-btn {
  font-size: 0.28rem;
  color: var(--main-color);
}
.shopping-cart .goods-list {
  margin-top: 0.88rem;
  width: 100%;
  overflow: hidden;
  /*加减按钮*/
  /*失效商品*/
  /*商品信息*/
}
.shopping-cart .goods-list .yd-spinner > span:first-child {
  background-color: #F5F5F5;
  border-radius: 0.04rem 0 0 0.04rem;
  border-color: #e1e1e1;
}
.shopping-cart .goods-list .yd-spinner > span:last-child {
  background-color: #F5F5F5;
  border-radius: 0 0.04rem 0.04rem 0;
  border-color: #e1e1e1;
}
.shopping-cart .goods-list .yd-spinner > input {
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  -webkit-appearance: none;
}
.shopping-cart .goods-list .yd-spinner > span:first-child > i:after {
  color: #bbb;
}
.shopping-cart .goods-list .yd-spinner > span:last-child > i:after {
  color: var(--main-color);
}
.shopping-cart .goods-list .title {
  height: 0.82rem;
  line-height: 0.82rem;
  font-size: 0.28rem;
  color: #333;
  padding-left: 0.24rem;
  background: #F5F5F5;
}
.shopping-cart .goods-list .goods-item {
  width: 100%;
  height: 2.6rem;
  position: relative;
  transition: all 0.2s;
  background: #fff;
  /*删除按钮*/
  /*&:last-child {
				margin-bottom: 1rem;
			}*/
  /*加减*/
  /*失效图片*/
}
.shopping-cart .goods-list .goods-item[data-type='0'] {
  transform: translate3d(0, 0, 0);
}
.shopping-cart .goods-list .goods-item[data-type='1'] {
  transform: translate3d(-1.8rem, 0, 0);
}
.shopping-cart .goods-list .goods-item .touch-item {
  width: 100%;
}
.shopping-cart .goods-list .goods-item .delete {
  width: 1.8rem;
  height: 2rem;
  background: #FF5252;
  font-size: 0.28rem;
  color: #fff;
  line-height: 2rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1.82rem;
  z-index: 3;
}
.shopping-cart .goods-list .goods-item .right {
  width: 88.8%;
  height: 100%;
  padding: 0.6rem 0;
  position: relative;
}
.shopping-cart .goods-list .goods-item .right .goods-img {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.24rem;
}
.shopping-cart .goods-list .goods-item .right .goods-info {
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
  color: #333;
}
.shopping-cart .goods-list .goods-item .right .goods-info .name {
  margin-bottom: 0.1rem;
  line-height: 0.4rem;
  width: 90%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.shopping-cart .goods-list .goods-item .right .goods-info .name .sourceName {
  position: relative;
  top: -0.05rem;
  display: inline-block;
  height: 0.3rem;
  line-height: 0.35rem;
  padding: 0 0.15rem;
  border-radius: 0.2rem;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 0.18rem;
}
.shopping-cart .goods-list .goods-item .right .goods-info .describe {
  font-size: 0.22rem;
  color: var(--main-color);
  padding: 0 0.12rem;
  min-width: 1.78rem;
  height: 0.36rem;
  line-height: 0.36rem;
  border-radius: 0.18rem;
  border: 0.02rem solid var(--main-color);
}
.shopping-cart .goods-list .goods-item .right .goods-info .price {
  font-size: 0.32rem;
  color: #FF4D4D;
  height: 0.4rem;
  line-height: 0.4rem;
  margin-top: 0.18rem;
}
.shopping-cart .goods-list .goods-item .right .goods-info .failure-price {
  color: #ccc;
}
.shopping-cart .goods-list .goods-item .spinner-box {
  position: absolute;
  top: 2rem;
  right: 0.24rem;
  width: 1.88rem;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}
.shopping-cart .goods-list .goods-item .spinner-box span {
  width: 0.48rem;
  height: 0.48rem;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shopping-cart .goods-list .goods-item .spinner-box span:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.shopping-cart .goods-list .goods-item .spinner-box span:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.shopping-cart .goods-list .goods-item .spinner-box span .minus {
  color: #bbb;
  width: 0.36rem;
}
.shopping-cart .goods-list .goods-item .spinner-box span .add {
  width: 0.36rem !important;
  height: 0.36rem !important;
}
.shopping-cart .goods-list .goods-item .spinner-box input {
  width: 0.92rem;
  height: 0.48rem;
  letter-spacing: 0;
  float: left;
  height: inherit;
  text-align: center;
  color: #666;
  border: none;
  font-size: 0.26rem;
  background-color: #fff;
}
.shopping-cart .goods-list .goods-item .failure-img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.shopping-cart .goods-list .goods-item .failure-img img {
  width: 1.56rem;
  height: 1.4rem;
}
.shopping-cart .select-box {
  width: 11.2%;
}
.shopping-cart .select-box input[type='checkbox'] {
  display: none;
}
.shopping-cart .select-box input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #999;
  border-radius: 50%;
}
.shopping-cart .select-box input[type='checkbox'] + label .select-checked {
  display: none;
}
.shopping-cart .select-box input[type='checkbox']:checked + label:before {
  position: absolute;
  border: 0;
}
.shopping-cart .select-box input[type='checkbox']:checked + label .select-checked {
  display: block;
}
.shopping-cart .select-box input[type='checkbox']:disabled + label:before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
}
.shopping-cart .select-box .select-normal {
  width: 18px;
  height: 18px;
  border: 1px solid #999;
  border-radius: 50%;
}
.shopping-cart .select-box .select-checked {
  width: 18px;
  height: 18px;
  display: none;
}
.shopping-cart .select-box .select-disabled {
  width: 18px;
  height: 18px;
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
}
.shopping-cart footer {
  width: 100%;
  height: 0.96rem;
  background: #fff;
  position: fixed;
  bottom: 0.98rem;
  padding-left: 0.24rem;
  z-index: 4;
  /*复选框*/
  /*结算按钮*/
  /*删除按钮*/
}
.shopping-cart footer .select-box {
  width: 1.31rem;
}
.shopping-cart footer label {
  width: auto;
}
.shopping-cart footer .label-name {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  margin-left: 0.16rem;
}
.shopping-cart footer .check-all {
  width: 2rem;
  /*.yd-checkbox-icon {
        border: 0.01rem solid @color-light-grey;
        width: 0.36rem !important;
        height: 0.36rem !important;
      }*/
  /*span {
        .sc(0.28rem, @color-dark-grey);
        line-height: 0.4rem;
        margin-left: 0.16rem;
      }

      .select-normal {
        .wh(0.36rem, 0.36rem);
        border: 0.01rem solid @color-light-grey;
        border-radius: 50%;
      }

      .select-checked {
        .wh(0.36rem, 0.36rem);
        display: none;
      }

      .select-disabled {
        .wh(0.36rem, 0.36rem);
        background: #f5f5f5;
        border: 0.01rem solid #e4e4e4;
        border-radius: 50%;
      }

      input[type="checkbox"] {
        width: 1rem;
        visibility: hidden;
        position: absolute;

        & + label:before {
          content: '';
          display: inline-block;
          .wh(0.36rem, 0.36rem);
          border: 0.01rem solid @color-light-grey;
          border-radius: 50%;
        }

        & + label {
          .select-checked {
            display: none;
          }
        }

        &:checked + label:before {
          position: absolute;
          border: 0;
        }

        &:checked + label {
          .select-checked {
            display: block;
          }
        }

        &:disabled + label:before {
          content: '';
          display: inline-block;
          .wh(0.36rem, 0.36rem);
          background: #f5f5f5;
          border: 0.01rem solid #e4e4e4;
          border-radius: 50%;
        }
      }*/
}
.shopping-cart footer .check-all .yd-checkbox > input[type='checkbox']:checked + .yd-checkbox-icon {
  background-color: var(--main-color);
  border-color: var(--main-color);
  /*width: 0.36rem !important;*/
  /*height: 0.36rem !important;*/
}
.shopping-cart footer .check-all .yd-checkbox-icon > i {
  width: 0.1rem !important;
  height: 0.2rem !important;
  border-width: 0.04rem;
}
.shopping-cart footer .check-all .yd-checkbox-text {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
  margin-left: 0.16rem;
}
.shopping-cart footer .total {
  font-size: 0.32rem;
  color: #FF5F5F;
  margin-right: 0.4rem;
  max-width: 3rem;
}
.shopping-cart footer .settlement {
  width: 2.5rem;
  height: 0.96rem;
  background: var(--main-color);
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.96rem;
  border: 0;
}
.shopping-cart footer .settlement:disabled {
  outline: none;
  border: none;
  opacity: 0.4;
}
.shopping-cart footer .settlement:active {
  outline: var(--main-color);
}
.shopping-cart footer .del-btn {
  width: 2.26rem;
  height: 0.68rem;
  border-radius: 0.48rem;
  border: 0.02rem solid #FF4D4D;
  font-size: 0.28rem;
  color: #FF4D4D;
  line-height: 0.69rem;
  text-align: center;
  margin-right: 0.24rem;
}
.shopping-cart .empty-box {
  width: 100%;
  height: 4.76rem;
  position: relative;
  font-size: 0.28rem;
  color: #999;
}
.shopping-cart .empty-box img {
  width: 3.64rem;
  height: 2.12rem;
  margin: 1.1rem 0 0.4rem;
}
